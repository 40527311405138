import Link, {Props as LinkProps} from './Link';

import {css} from '@emotion/react';
import React from 'react';

export interface Props extends LinkProps {}

export default function UndecoratedLink(props: Props): JSX.Element {
  const {children, sx: style, ...linkProps} = props;

  return (
    <Link
      {...linkProps}
      sx={[
        css({
          borderRadius: '5px',
          color: 'inherit',
          textDecoration: 'none',
        }),
        style,
      ]}
    >
      {children}
    </Link>
  );
}

import {linkToApp} from '../../../../url/linkToApp';
import Smile from '../../../design_system/icon/Smile';
import {useGlobalNavigationQuery} from '../../__generated__/GlobalNavigation.graphql';
import IconNavItem from '../IconNavItem';

import React from 'react';
import {useTranslation} from 'react-i18next';

export default function MentorshipNavItem(): JSX.Element {
  const {t} = useTranslation();
  const {data} = useGlobalNavigationQuery({
    fetchPolicy: 'cache-only',
  });

  if (data?.featureFlags.mentorshipRequest) {
    return (
      <IconNavItem
        description={t('nav.side-nav-conversation-description')}
        icon={<Smile />}
        label={t('nav.side-nav-converation-link-label')}
        to={linkToApp('/mentorship/confirmed')}
      />
    );
  }

  return (
    <IconNavItem
      description={t('nav.side-nav-conversation-description')}
      disable={{reason: 'Coming soon'}}
      icon={<Smile />}
      label={t('nav.side-nav-converation-link-label')}
      to={linkToApp('/mentorship/confirmed')}
    />
  );
}

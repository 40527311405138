import useAppTheme from '../../design_system/hooks/useAppTheme';
import Flex from '../flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly items: React.ReactNode;
}

export default function SideNav(props: Props): JSX.Element {
  const {items} = props;
  const {spacing} = useAppTheme();

  return (
    <Flex
      sx={css({
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: spacing.x32,
      })}
    >
      {items}
    </Flex>
  );
}

import isNonEmptyString from '../../../isNonEmptyString';
import useAppTheme from '../../design_system/hooks/useAppTheme';
import {AppIconProps} from '../../design_system/icon/AppIconProps';
import UndecoratedLink from '../../design_system/Link/UndecoratedLink';
import {ContentColor} from '../../design_system/theme/useContentPalette';
import LinkText from '../../design_system/typography/LinkText';
import UtilityText from '../../design_system/typography/UtilityText';
import Flex from '../flex/Flex';
import useLogout from '../hooks/useLogout';

import {css} from '@emotion/react';
import React from 'react';
import {matchPath, useLocation, useMatch} from 'react-router-dom';

export interface Disable {
  readonly reason?: string;
}

export enum NavAction {
  Logout = 'nav-action://logout',
}

export interface Props {
  readonly icon: React.ReactElement<AppIconProps>;
  readonly label: string;
  readonly to: string | NavAction;
  readonly description?: string;
  readonly disable?: Disable;
  readonly paths?: string[];
}

export default function IconNavItem(props: Props): JSX.Element {
  const {commit, isInProgress} = useLogout();
  const {description, disable, icon, label, paths = [], to} = props;
  const {palettes, spacing} = useAppTheme();
  let match = useMatch(to);
  const location = useLocation();
  for (const path of paths) {
    if (match != null) {
      break;
    }
    match = matchPath(path, location.pathname);
  }
  const isDisabled = disable != null || isInProgress;
  const color: ContentColor =
    isDisabled || match == null
      ? ContentColor.PRIMARY_SUBDUED
      : ContentColor.SECONDARY;

  const specialActions: Record<string, () => void> = {
    [NavAction.Logout]: () => void commit(),
  };

  return (
    <UndecoratedLink
      sx={css({
        pointerEvents: isDisabled ? 'none' : 'auto',
      })}
      to={to}
    >
      <Flex
        css={css({
          columnGap: spacing.x12,
        })}
        onClick={(e) => {
          const action = specialActions[to];
          if (action != null) {
            e.preventDefault();
            e.stopPropagation();
            action();
          }
        }}
      >
        <div
          css={css({
            flex: '0 0 auto',
          })}
        >
          {React.cloneElement(icon, {color, isDisabled})}
        </div>
        <Flex
          direction="column"
          sx={css({rowGap: spacing.x4, alignItems: 'flex-start'})}
        >
          <Flex css={css({columnGap: spacing.x8})}>
            <LinkText
              color={color}
              isDisabled={isDisabled}
            >
              {label}
            </LinkText>
            {isNonEmptyString(disable?.reason) && (
              <Flex
                css={css({
                  borderRadius: '3px',
                  background: palettes.background.neutral.disabled,
                  padding: '2px 4px 2px 4px',
                })}
              >
                <UtilityText isDisabled={isDisabled}>
                  {disable.reason}
                </UtilityText>
              </Flex>
            )}
          </Flex>
          {description != null && (
            <UtilityText
              color={ContentColor.PRIMARY_SUBDUED}
              isDisabled={isDisabled}
            >
              {description}
            </UtilityText>
          )}
        </Flex>
      </Flex>
    </UndecoratedLink>
  );
}

import useAppTheme from '../hooks/useAppTheme';

import {css, keyframes} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly 'data-testid'?: string;
}

export default function CircularProgress(props: Props) {
  const {'data-testid': testID} = props;
  const {palettes} = useAppTheme();

  const rotation = keyframes({
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  });

  return (
    <span
      css={css({
        height: 48,
        width: 48,
        border: `3px solid ${palettes.background.neutral.default}`,
        borderRadius: '50%',
        display: 'inline-block',
        position: 'relative',
        boxSizing: 'border-box',
        animation: `${rotation} 1s linear infinite`,

        '&::after': {
          content: '""',
          boxSizing: 'border-box',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 40,
          height: 40,
          borderRadius: '50%',
          border: `3px solid`,
          borderColor: ` ${palettes.background.accent.default} transparent`,
        },
      })}
      data-testid={testID}
    />
  );
}

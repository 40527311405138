import {useLogoutMutation} from '../__generated__/GlobalNavigation.graphql';

import {useCallback, useState} from 'react';

export type MutationObject = Readonly<{
  commit: () => Promise<void>,
  isInProgress: boolean,
}>;


export default function useLogout(): MutationObject {
  const [isInProgress, setIsInProgress] = useState(false);
  const [commitImpl] = useLogoutMutation();
  const commit = useCallback(async () => {
    setIsInProgress(true);
    await commitImpl();
    setIsInProgress(false);
    location.reload();
  }, [commitImpl]);
  return {
    commit,
    isInProgress,
  };
}
import SideNav from './Nav/SideNav';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly navItems: React.ReactNode;
  readonly children?: React.ReactNode;
}

export default function SideNavPageContentWrapper(props: Props): JSX.Element {
  const {children, navItems} = props;
  const {palettes, spacing} = useAppTheme();
  return (
    <div
      css={css({
        display: 'grid',
        gridTemplateColumns: '290px 1fr',
        paddingTop: spacing.x60,
        minHeight: '90vh',

        [useMediaQuery(MediaQuery.SmallAndMedium)]: {
          gridTemplateColumns: '1fr',
        },
      })}
    >
      <div
        css={[
          css({
            boxSizing: 'border-box',
            paddingRight: 30,
            paddingLeft: 30,
            paddingBottom: spacing.x60,

            [useMediaQuery(MediaQuery.SmallAndMedium)]: {
              display: 'none',
            },
          }),
        ]}
      >
        <SideNav items={navItems} />
      </div>
      <div
        css={css({
          borderLeft: `2px solid ${palettes.background.neutral.disabled}`,
          paddingLeft: spacing.x60,
          paddingRight: spacing.x60,

          [useMediaQuery(MediaQuery.SmallAndMedium)]: {
            paddingLeft: spacing.x20,
            paddingRight: spacing.x20,
          },
        })}
      >
        {children}
      </div>
    </div>
  );
}

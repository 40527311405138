import useAppTheme from '../../lib/components/design_system/hooks/useAppTheme';
import Link from '../../lib/components/design_system/icon/Link';
import TextLink from '../../lib/components/design_system/Link/TextLink';
import {ContentColor} from '../../lib/components/design_system/theme/useContentPalette';
import BannerToast from '../../lib/components/design_system/toast/BannerToast';
import DashboardPageLayout from '../../lib/components/layout/DashboardPageLayout';
import {linkToApp} from '../../lib/url/linkToApp';

import {css} from '@emotion/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet} from 'react-router-dom';

export default function HomePage(): JSX.Element {
  const {t} = useTranslation();
  const {spacing} = useAppTheme();
  return (
    <DashboardPageLayout>
      <div
        css={css({
          marginBottom: spacing.x60,
        })}
      >
        <BannerToast
          action={
            <TextLink
              textProps={{
                color: ContentColor.PRIMARY_CONTRAST,
              }}
              to={linkToApp('/calendar/connections')}
            >
              {t('pages.my-page-home.banner-toast.link')}
              <Link
                color={ContentColor.PRIMARY_CONTRAST}
                size={14}
              />
            </TextLink>
          }
          description={t('pages.my-page-home.banner-toast.description')}
        />
      </div>
      <Outlet />
    </DashboardPageLayout>
  );
}

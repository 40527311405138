import {useGlobalNavigationQuery} from './__generated__/GlobalNavigation.graphql';
import PolicyFooter from './Footer/PolicyFooter';
import GlobalNavigationLayout, {
  Props as GlobalNavigationLayoutProps,
} from './GlobalNavigationLayout';
import {ViewerIDContext} from './hooks/useViewerID';
import SideNavPageContentWrapper from './SideNavPageContentWrapper';
import useMyPageSideNavControls from '../../hooks/useMyPageSideNavControls';
import {NEXT_URL_SEARCH_PARAM_KEY} from '../../hooks/useNavigateToNextUrl';
import {linkToApp} from '../../url/linkToApp';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';
import ContentLoadingProgress from '../design_system/progress/ContentLoadingProgress';

import {css} from '@emotion/react';
import React, {useEffect} from 'react';
import {createSearchParams, useLocation, useNavigate} from 'react-router-dom';

type OmitKeys = 'extraNav' | 'footer' | 'user' | 'featureFlags';
export interface Props extends Omit<GlobalNavigationLayoutProps, OmitKeys> {}

function MenuItemWrapper(props: {children: React.ReactNode}): JSX.Element {
  const {children} = props;
  const {spacing} = useAppTheme();
  return (
    <div
      css={css({
        display: 'none',

        [useMediaQuery(MediaQuery.SmallAndMedium)]: {
          display: 'inherit',
          width: '100%',
          '& + &': {
            marginTop: spacing.x24,
          },
        },
      })}
    >
      {children}
    </div>
  );
}

export default function DashboardPageLayout(props: Props): JSX.Element {
  const {children, ...others} = props;
  const {data, loading} = useGlobalNavigationQuery();
  const myPageSideNavControls = useMyPageSideNavControls();
  const menuControls = myPageSideNavControls.map((control, i) => {
    return <MenuItemWrapper key={i}>{control}</MenuItemWrapper>;
  });

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!loading && data?.viewer == null) {
      navigate({
        pathname: linkToApp('/login'),
        search: createSearchParams({
          [NEXT_URL_SEARCH_PARAM_KEY]: location.pathname,
        }).toString(),
      });
    }
  }, [data?.viewer, loading, location.pathname, navigate]);

  if (loading || data?.viewer == null) {
    return <ContentLoadingProgress />;
  }

  return (
    <ViewerIDContext.Provider value={{id: data.viewer.id}}>
      <GlobalNavigationLayout
        extraNav={menuControls}
        featureFlags={data?.featureFlags}
        footer={<PolicyFooter />}
        placement="app"
        user={data?.viewer}
        {...others}
      >
        <SideNavPageContentWrapper navItems={myPageSideNavControls}>
          {children}
        </SideNavPageContentWrapper>
      </GlobalNavigationLayout>
    </ViewerIDContext.Provider>
  );
}

import Bell from '../components/design_system/icon/Bell';
import Calendar from '../components/design_system/icon/Calendar';
import Compass from '../components/design_system/icon/Compass';
import Home from '../components/design_system/icon/Home';
import LogOut from '../components/design_system/icon/LogOut';
import MessageCircle from '../components/design_system/icon/MessageCircle';
import User from '../components/design_system/icon/User';
import NavDivider from '../components/layout/HorizontalDivider';
import IconNavItem, {NavAction} from '../components/layout/Nav/IconNavItem';
import MentorshipNavItem from '../components/layout/Nav/MyPageSideNavItems/MentorshipNavItem';
import {linkToApp} from '../url/linkToApp';

import React from 'react';
import {useTranslation} from 'react-i18next';

export default function useMyPageSideNavControls() {
  const {t} = useTranslation();

  return [
    <IconNavItem
      description={t('nav.side-nav-home-description')}
      icon={<Home />}
      key="home"
      label={t('nav.side-nav-home-link-label')}
      to={linkToApp('/')}
    />,
    <IconNavItem
      description={t('nav.side-nav-discover-description')}
      icon={<Compass />}
      key="discover"
      label={t('nav.side-nav-discover-link-label')}
      paths={['/app/discover', '/app/member/:id']}
      to={linkToApp('/discover')}
    />,
    <MentorshipNavItem key="mentorship" />,
    <IconNavItem
      disable={{reason: 'Coming soon'}}
      icon={<MessageCircle />}
      key="message"
      label={t('nav.side-nav-message-label')}
      to={linkToApp('/message')}
    />,
    <NavDivider key="divider-1" />,
    <IconNavItem
      icon={<Calendar />}
      key="calendar"
      label={t('nav.side-nav-calendar-label')}
      to={linkToApp('/calendar')}
    />,
    <IconNavItem
      disable={{reason: 'Coming soon'}}
      icon={<Bell />}
      key="notification"
      label={t('nav.side-nav-notification-label')}
      to={linkToApp('/notification')}
    />,
    <NavDivider key="divider-2" />,
    <IconNavItem
      icon={<User />}
      key="my-profile"
      label={t('nav.side-nav-my-profile-label')}
      paths={[
        linkToApp('/profile/wizard'),
        linkToApp('/profile/wizard/experience'),
        linkToApp('/profile/wizard/experience/education'),
        linkToApp('/profile/wizard/experience/profession'),
        linkToApp('/profile/wizard/biography'),
        linkToApp('/profile/wizard/goal'),
        linkToApp('/profile/wizard-done'),
      ]}
      to={linkToApp('/my-profile')}
    />,
    <IconNavItem
      icon={<LogOut />}
      key="logout"
      label={t('nav.side-nav-log-out-label')}
      to={NavAction.Logout}
    />,
  ];
}

import FormatText from './FormatText';
import {BaseTextProps, FontFamily} from './options';
import useSharedHTMLTextProps from './useSharedHTMLTextProps';
import useSharedTextCSS from './useSharedTextCSS';
import {CSSStyles} from '../../../types/CSSStyles';

import {css} from '@emotion/react';
import React from 'react';

export type BodyTextSize = 'L' | 'M' | 'S';

export interface Props extends BaseTextProps {
  readonly children: React.ReactNode;
  readonly size: BodyTextSize;
  readonly display?: 'block' | 'inline';
}

export default function BodyText(props: Props): JSX.Element {
  const {children, display, size} = props;
  const sharedCSS = useSharedTextCSS(props);
  const htmlProps = useSharedHTMLTextProps(props);
  let style: CSSStyles;
  switch (size) {
    case 'L':
      style = css({
        fontSize: 18,
      });
      break;
    case 'M':
      style = css({
        fontSize: 16,
      });
      break;
    case 'S':
      style = css({
        fontSize: 14,
      });
  }

  return (
    <span
      css={[
        sharedCSS,
        css({
          display,
          fontFamily: FontFamily.NOTO_SANS_JS,
          fontWeight: 400,
          lineHeight: '1.4em',
        }),
        style,
      ]}
      {...htmlProps}
    >
      <FormatText>{children}</FormatText>
    </span>
  );
}

import {CSSStyles} from '../../../types/CSSStyles';
import Flex from '../../layout/flex/Flex';
import Gap from '../../layout/flex/Gap';
import {ButtonColor} from '../Button/options';
import SquareIconButton from '../Button/SquareIconButton';
import useAppTheme from '../hooks/useAppTheme';
import {AppIconProps} from '../icon/AppIconProps';
import Close from '../icon/Close';
import {ContentColor} from '../theme/useContentPalette';
import BodyText from '../typography/BodyText';

import {css} from '@emotion/react';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

export interface Props {
  readonly description: React.ReactNode;
  readonly action?: React.ReactNode;
  readonly canDismiss?: boolean;
  readonly icon?: React.ReactElement<AppIconProps>;
  readonly sx?: CSSStyles;
}

export default function BannerToast(props: Props): JSX.Element | null {
  const {action, canDismiss, description, icon, sx} = props;
  const {t} = useTranslation();
  const {spacing} = useAppTheme();
  const [isDismissed, setIsDismissed] = useState(false);

  if (isDismissed) {
    return null;
  }

  const dismissButton = canDismiss && (
    <SquareIconButton
      color={ButtonColor.TRANSPARENT}
      icon={
        <Close
          color={ContentColor.PRIMARY_CONTRAST}
          size={20}
        />
      }
      label={t('common-strings.close')}
      onPress={() => setIsDismissed(true)}
    />
  );

  return (
    <Flex
      css={[
        css({
          background: 'linear-gradient(90deg, #FF3A8B 1.82%, #FFBA4A 96.9%)',
          borderRadius: 6,
          columnGap: spacing.x8,
          paddingLeft: spacing.x16,
          flexWrap: 'wrap',
          paddingTop: spacing.x16,
          paddingBottom: spacing.x16,
          paddingRight: spacing.x16,
          rowGap: spacing.x12,
        }),
        sx,
      ]}
    >
      {icon}

      <BodyText
        color={ContentColor.PRIMARY_CONTRAST}
        size="S"
      >
        {description}
      </BodyText>

      <Gap />

      <div>{action}</div>
      {dismissButton}
    </Flex>
  );
}

import CircularProgress from './CircularProgress';
import Flex from '../../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly 'data-testid'?: string;
}

export default function ContentLoadingProgress(props: Props): JSX.Element {
  const {'data-testid': testID} = props;
  return (
    <Flex
      data-testid={testID}
      sx={css({
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        width: '100%',
      })}
    >
      <CircularProgress />
    </Flex>
  );
}

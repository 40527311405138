import useAppTheme from '../design_system/hooks/useAppTheme';

import {css} from '@emotion/react';
import React from 'react';

export default function HorizontalDivider(): JSX.Element {
  const {palettes} = useAppTheme();

  return (
    <div
      css={css({
        backgroundColor: palettes.background.neutral.disabled,
        height: 2,
        width: '100%',
      })}
    />
  );
}

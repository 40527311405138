import Button, {Props as ButtonProps} from './Button';
import {AppIconProps} from '../icon/AppIconProps';

import {css} from '@emotion/react';
import React, {forwardRef} from 'react';

type OmitKeys = 'labelIsHidden' | 'size';
export interface Props extends Omit<ButtonProps, OmitKeys> {
  readonly icon: React.ReactElement<AppIconProps>;
  readonly size?: number;
}

function SquareIconButton(props: Props, ref: React.ForwardedRef<unknown>) {
  const {size = 38, ...otherProps} = props;
  return (
    <Button
      sx={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        height: size,
        width: size,
        padding: 0,
      })}
      {...otherProps}
      labelIsHidden={true}
      ref={ref}
    />
  );
}

export default forwardRef(SquareIconButton);
